import React from 'react';
import Accordion from '../../Accordion';
import SectionTitle from './SectionTitle';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';

export const SectionTips = () => {
  return (
    <div>
      <section aria-describedby="tips">
        <SectionTitle title="Tips til energieffektivisering" id="tips" />
        <Paragraph>
          Det er mange ting du kan gjøre for å spare strøm, og dermed penger. Her er våre beste tips til hva du kan
          gjøre for å energieffektivisere boligen din.
        </Paragraph>

        <Title level={5}>Etterisolering</Title>
        <Paragraph>
          Etterisolering gir en umiddelbar og langvarig effekt på oppvarmingskostnadene. De mest lønnsomme tiltakene i
          denne kategorien er:
        </Paragraph>

        <Accordion header="Etterisolering av bjelkelag mot kalde loft">
          <Paragraph style={{ fontSize: 13 }}>
            Uisolerte eller dårlig isolerte bjelkelag mot kalde loft gjør at mye av den varme lufta i øverste etasje
            forsvinner opp på loftet. Det er i stor grad bortkastet energi. Øker du isolasjonstykkelsen i bjelkelaget
            mot kalde loft fra 10 til 30 cm i en typisk bolig på 100 m2, kan du spare ca. 3000 kWh årlig.{' '}
          </Paragraph>
        </Accordion>

        <Accordion header="Etterisolering av yttervegger">
          <Paragraph style={{ fontSize: 13 }}>
            Mye av varmetapet i en bolig foregår gjennom ytterveggene, og det er mye å hente på å øke
            isolasjonstykkelsen. Øker du fra 10 til 25 cm isolasjon og monterer ny vindsperre vil du kunne oppnå en
            besparelse på 39 kWh i oppvarmingskostnad per kvadratmeter isolert areal.
          </Paragraph>
        </Accordion>

        <Accordion header="Bytte ut gamle vinduer">
          <Paragraph style={{ fontSize: 13 }}>
            Dersom du har vinduer fra 1980-tallet eller tidligere, har de stort varmetap. I en bolig med totalt 15 m2
            vindusareal, der vinduene har en U-verdi på 2,5, vil du kunne spare ca. 2300 kWh i året ved å bytte til
            trelagsvinduer med U-verdi på 0,8.
          </Paragraph>
        </Accordion>

        <Accordion header="Etterisolering av bjelkelag mot kald kjeller">
          <Paragraph style={{ fontSize: 13 }}>
            En økning fra 10 til 20 centimeter i bjelkelag vil kunne gi en besparelse 15 kWh per kvadratmeter isolert
            areal. I tillegg blir ikke gulvene over kjeller og kryperom så kalde.
          </Paragraph>
        </Accordion>

        <Title level={4}>Energieffektive produkter og løsninger</Title>
        <Paragraph>
          Er det ikke tid eller penger til større etterisoleringsprosjekter kan energieffektive produkter og løsninger
          være en god vei å gå.
        </Paragraph>

        <Title level={4}>Smart strømstyring</Title>
        <Paragraph>
          Smart strømstyring innebærer å styre såkalt flyttbart forbruk til tider på døgnet der strømprisene er lavest.
          Dette gjelder typisk forbruk til lading av elbil, oppvarming av varmtvann og gulvvarme. Det er både mulig å
          installere systemer som styrer flere strømforbrukere eller å investere i enkeltprodukter som styres etter
          strømprisen, typisk varmtvannsberedere og ladestasjoner for elbil.{' '}
        </Paragraph>

        <Title level={4}>Energieffektiv oppvarming</Title>
        <Paragraph>
          Har du eldre eller lite energieffektive oppvarmingsprodukter kan de byttes ut med nye og mer effektive.
          Eksempelvis vil du kunne halvere kostnaden ved romoppvarming ved å gå fra elektrisk oppvarming til
          luft/luft-varmepumpe. Ønsker du ikke å bytte ut eksisterende oppvarmingsprodukter, er det lurt å stille dem
          inn på dag- og nattsenking slik at temperaturen er lavere på dag- og nattestid. Dette vil kunne gi en
          reduksjon i strømforbruk på mellom 15 og 25 prosent.
        </Paragraph>

        <Title level={4}>Solcelleanlegg</Title>
        <Paragraph>
          Har du takarealer med god solinnstråling kan et solcelleanlegg være en god investering da det dekker en god
          del av boligens strømforbruk. Med en plusskundeavtale kan du også tjene noen kroner på å selge
          overskuddsenergi tilbake på nettet.
        </Paragraph>
      </section>
    </div>
  );
};
