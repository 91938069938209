import { useAuth0 } from '@auth0/auth0-react';
import { Button, Group, Loader, Modal, ModalHeader, Space } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllUsers, matchMeterpointWithUser } from '../../api/admin';
import { MatchingUser } from '../../types/MatchingUser';
import { Meterpoint } from '../../types/Meterpoint';

type ModalComponentProps = {
  opened: boolean;
  onClose: () => void;
  meterpoint?: Meterpoint;
};

export const MatchingModal: React.FC<ModalComponentProps> = ({ opened, onClose, meterpoint }) => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const defaultWidth = 100;

  const [users, setUsers] = useState<MatchingUser[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<MatchingUser | null>();

  // Kall for å koble meterpoint med NO_MATCH opp mot en eksisterende bruker
  const { mutate: matchUser, isLoading: isMatchingUser } = useMutation({
    mutationFn: async (userId: string) => {
      if (meterpoint) {
        const accessToken = await getAccessTokenSilently();
        return await matchMeterpointWithUser(accessToken, userId, meterpoint.meterpointId);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getAllUsers(id || '1');
        setUsers(users);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (opened && meterpoint) fetchData();
  }, [opened, id, meterpoint]);

  // Håndtering ved Submit av valgt rad med brukerinfo
  const handleSubmit = useCallback(() => {
    if (selectedRowKeys) {
      matchUser(selectedRowKeys.id.toString());
    }
    setSelectedRowKeys(null);
    onClose();
  }, [matchUser, selectedRowKeys, onClose]);

  // Når Modal lukkes
  const handleClose = () => {
    setSelectedRowKeys(null);
    onClose();
  };

  const columns: ColumnsType<MatchingUser> = [
    {
      title: 'Adresse',
      width: defaultWidth * 1.3,
      render: (_text: any, m: MatchingUser) => `${m.bruksenhet?.completeAddress}`,
      sorter: (a, b) => (a.bruksenhet?.completeAddress || '').localeCompare(b.bruksenhet?.completeAddress || ''),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Navn',
      width: defaultWidth,
      render: (_text: any, m: MatchingUser) => `${m.firstName} ${m.lastName}`,
    },
    {
      title: 'Epost',
      width: defaultWidth * 1.2,
      render: (_text: any, m: MatchingUser) => `${m.email}`,
    },
    {
      title: 'Tlf.',
      width: defaultWidth * 0.6,
      render: (_text: any, m: MatchingUser) => `${m.mobile}`,
    },
  ];

  return (
    <Modal size="80%" opened={opened} onClose={handleClose} centered>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ModalHeader>
          <div>
            <span style={{ fontSize: '1em', display: 'inline-block', minWidth: '150px' }}>MålerpunktID:</span>{' '}
            {meterpoint?.meterpointId}
            <br />
            <span style={{ fontSize: '1em', display: 'inline-block', minWidth: '150px' }}>Målerpunktadresse:</span>{' '}
            {meterpoint?.completeAddress}
          </div>
        </ModalHeader>
        <Space h="xl" />
        {users && users.length > 0 ? (
          <>
            <Table
              bordered
              dataSource={users}
              columns={columns}
              rowClassName="editable-row"
              rowKey={(record) => `${record.id}`}
              pagination={false}
              sticky
              scroll={{ x: 200, y: 600 }}
              rowSelection={{
                type: 'radio',
                onSelect: (value) => {
                  setSelectedRowKeys(value);
                },
              }}
            ></Table>
            <Space h="xl" />
            <Group>
              <Button variant="filled" disabled={!selectedRowKeys} onClick={handleSubmit}>
                Lagre og oppdater
              </Button>
            </Group>
          </>
        ) : (
          <Loader />
        )}
        {isMatchingUser && <Loader />}
        <Space h="lg" />
      </div>
    </Modal>
  );
};
