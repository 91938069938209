import { DailyMeterpointUsage } from '../../../types/DailyMeterpointUsage';
import { MonthlyMeterpointUsage } from '../../../types/MonthlyMeterpointUsage';
import Card from '../Card';
import SectionTitle from '../InfoSections/SectionTitle';
import ElectricityUsageChart from './ElectricityUsageChart';
import { MonthlyTemperature } from '../../../types/MonthlyTemperature';
import React from 'react';
import Paragraph from 'antd/es/typography/Paragraph';

interface SectionUsageCircleProps {
  dailyConsumption?: DailyMeterpointUsage[];
  monthlyTemperature?: MonthlyTemperature[];
  monthlyConsumption?: MonthlyMeterpointUsage[];
}

export const SectionUsageCircle: React.FC<SectionUsageCircleProps> = ({
  monthlyConsumption,
  dailyConsumption,
  monthlyTemperature,
}) => {
  return (
    <section aria-describedby="overview">
      <SectionTitle title="Strømforbruket ditt gjennom året" id="circlePowerConsumption" />

      <Paragraph>
        Hjulet nedenfor viser deg ditt strømforbruk for hver dag gjennom året som var. I den innerste sirkelen kan du
        samtidig se gjennomsnittstemperaturen for hver måned. Den stiplede linjen viser indeksen for gjennomsnittlig
        strømforbruk, og hvordan din bolig forholder seg mot denne. En daglig søyle over denne linjen betyr et økt
        strømforbruk.
      </Paragraph>

      <Card>
        <ElectricityUsageChart
          monthlyTemperature={monthlyTemperature}
          dailyConsumption={dailyConsumption}
          monthlyConsumption={monthlyConsumption}
        />
      </Card>
    </section>
  );
};
