import axios from 'axios';
import { DailyMeterpointUsage } from '../types/DailyMeterpointUsage';
import { Meterpoint } from '../types/Meterpoint';
import { MonthlyMeterpointUsage } from '../types/MonthlyMeterpointUsage';
import { MonthlyTemperature } from '../types/MonthlyTemperature';
import { REDUCE_API_BASEURL } from '../utils/constants';

export const getMeterpointByUuid = async (uuid: string) => {
  try {
    return (await axios.get<Meterpoint>(`${REDUCE_API_BASEURL}/meterpoints/${uuid}`)).data;
  } catch (error) {
    console.error('Error getting meterpoint:', error);
    throw error;
  }
};

export const getMonthlyUsage = async (uuid: string) => {
  // Default: Siste 12 måneder.
  const currentDate = new Date();
  const lastTwelveMonthsStartDate = new Date(
    currentDate.getFullYear() - 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const from = lastTwelveMonthsStartDate.toISOString().split('T')[0];
  const to = currentDate.toISOString().split('T')[0];

  try {
    return (
      await axios.get<MonthlyMeterpointUsage[]>(
        `${REDUCE_API_BASEURL}/meterpoints/${uuid}/consumption/monthly/${from}/${to}`
      )
    ).data;
  } catch (error) {
    console.error('Error getting monthly consumption:', error);
    throw error;
  }
};

export const getMaxThreeYearUsage = async (uuid: string) => {
  // Default: Inneværende og 2 foregående år
  const currentDate = new Date();
  const lastYear = currentDate.getFullYear() - 2;

  const dt = new Date();
  dt.setFullYear(lastYear);
  dt.setMonth(0, 1); // 01/01
  const from = dt.toISOString().split('T')[0];

  const to = currentDate.toISOString().split('T')[0];

  try {
    return (
      await axios.get<MonthlyMeterpointUsage[]>(
        `${REDUCE_API_BASEURL}/meterpoints/${uuid}/consumption/monthly/${from}/${to}`
      )
    ).data;
  } catch (error) {
    console.error('Error getting monthly consumption:', error);
    throw error;
  }
};

export const getDailyUsage = async (uuid: string) => {
  const dt = new Date();
  const lastYear = dt.getFullYear() - 1;

  // Dato til akseptert stringformat
  dt.setFullYear(lastYear);
  dt.setMonth(0, 1);
  const from = dt.toISOString().split('T')[0];

  const toDate = new Date();
  toDate.setFullYear(lastYear);
  toDate.setMonth(11, 31);
  const to = toDate.toISOString().split('T')[0];

  try {
    return (
      await axios.get<DailyMeterpointUsage[]>(
        `${REDUCE_API_BASEURL}/meterpoints/${uuid}/consumption/daily/${from}/${to}`
      )
    ).data;
  } catch (error) {
    console.error('Error getting daily consumption:', error);
    throw error;
  }
};

export const getDailyComparedUsage = async (uuid: string) => {
  // Default: Inneværende og forrige år
  const currentDate = new Date();
  const lastYear = currentDate.getFullYear() - 1;

  const dt = new Date();
  dt.setFullYear(lastYear);
  dt.setMonth(0, 1); // 01/01
  const from = dt.toISOString().split('T')[0];

  const to = currentDate.toISOString().split('T')[0];

  try {
    return (
      await axios.get<DailyMeterpointUsage[]>(
        `${REDUCE_API_BASEURL}/meterpoints/${uuid}/consumption/daily/${from}/${to}`
      )
    ).data;
  } catch (error) {
    console.error('Error getting daily consumption:', error);
    throw error;
  }
};

export const getMonthlyTemperatures = async (uuid: string) => {
  // Default: Inneværende og forrige år
  const currentDate = new Date();

  const dt = new Date();
  dt.setFullYear(dt.getFullYear() - 1);
  dt.setDate(1);
  const from = dt.toISOString().split('T')[0];

  const to = currentDate.toISOString().split('T')[0];

  try {
    return (
      await axios.get<MonthlyTemperature[]>(
        `${REDUCE_API_BASEURL}/meterpoints/${uuid}/temperature/monthly/${from}/${to}`
      )
    ).data;
  } catch (error) {
    console.error('Error getting monthly temperatures:', error);
    throw error;
  }
};
