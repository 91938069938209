import { Button, createTheme, Text } from '@mantine/core';

export const theme = createTheme({
  colors: {
    blue: [
      '#777171',
      '#cbd3e6',
      '#b1beda',
      '#99a9cd',
      '#8094c0',
      '#697fb3',
      '#526ba6',
      '#3d5698',
      '#28428b',
      '#152c7d',
    ],
    green: [
      '#3A503B',
      '#dcf9eb',
      '#c8f3e3',
      '#b2eccb',
      '#9ce5b3',
      '#86df9c',
      '#71d984',
      '#5bce6d',
      '#45c855',
      '#B0C99F',
    ],
    teal: [
      '#ecf9f8',
      '#c5edea',
      '#9ee1dc',
      '#77d5ce',
      '#84d9d3',
      '#36afa7',
      '#2a8882',
      '#1e615d',
      '#123a38',
      '#061313',
    ],
    chart: [
      '#ffb472',
      '#eae6e3',
      '#69b1bf',
      '#b5d9e3',
      '#a5d399',
      '#99c1b0',
      '#6264c4',
      '#5c61c1',
      '#142445',
      '#2e2e47',
      '#76815E',
      '#A3B18E',
      '#BBD3A9',
      '#BCE9F5',
    ],
    reduce: [
      '#dbd3c9',
      '#828282',
      '#afc8b2',
      '#8bb2ab',
      '#69b1bf',
      '#6a7dae',
      '#62c46f',
      '#388d5a',
      '#142445',
      '#0c0e29',
      // new colors
      '#97BE76',
      '#F87C7C',
      '#FAD155',
      '#8BDDE9',
    ],
  },
  primaryColor: 'blue',
  primaryShade: 9,
  fontFamily: 'DM Sans, sans-serif',
  headings: {
    fontFamily: 'DM Sans, sans-serif',
    sizes: {
      h1: {
        fontWeight: '500',
      },
      h2: {
        fontWeight: '500',
      },
    },
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        color: '#1bafa4',
        variant: 'outline',
        size: 'sm',
      },
    }),
    Text: Text.extend({
      defaultProps: {
        size: 'lg',
      },
    }),
  },
});
