import styles from './styles.module.css';
import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 448 166" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.logo}>
      <g clipPath="url(#clip0_476_209)">
        <path
          d="M396.656 64.7239L393.862 78.9061H424.173L421.845 90.6645H391.438L388.453 105.778H424.089L421.57 117.907H371.011L384.072 52.6162H433.413L430.894 64.7451H396.656V64.7239Z"
          fill="#142445"
        />
        <path
          d="M47.4257 96.634L58.8031 116.785H42.8535L32.5874 98.5073H19.7176L16.0768 116.785H0.878662L13.9389 51.4946H40.5251C56.7605 51.4946 66.0847 58.9561 66.0847 71.7306C66.0847 84.5051 59.0889 92.9932 47.4257 96.634ZM50.7807 73.1277C50.7807 66.788 46.3038 63.8034 38.5565 63.8034H26.6182L22.046 86.4737H34.9158C44.8962 86.4737 50.7701 81.8063 50.7701 73.1382L50.7807 73.1277Z"
          fill="#142445"
        />
        <path
          d="M154.416 51.4839H181.743C201.895 51.4839 213.928 62.0252 213.928 78.7263C213.928 101.767 197.418 116.785 173.255 116.785H141.356L154.416 51.4945V51.4839ZM173.721 104.371C189.395 104.371 198.624 93.7339 198.624 79.5624C198.624 69.8571 192.369 63.888 180.06 63.888H167.095L158.978 104.371H173.721Z"
          fill="#142445"
        />
        <path
          d="M227.55 95.0463C227.55 92.8026 227.825 90.4741 228.301 88.0505L235.572 51.4839H250.781L243.51 87.7647C243.139 89.818 242.949 91.5854 242.949 93.2682C242.949 101.195 247.426 105.016 255.543 105.016C264.688 105.016 270.001 99.9783 272.52 87.7647L279.801 51.4839H294.629L287.263 88.7067C283.347 108.011 272.244 117.907 253.871 117.907C237.456 117.907 227.571 109.144 227.571 95.0569L227.55 95.0463Z"
          fill="#142445"
        />
        <path
          d="M303.297 89.5431C303.297 67.2539 319.617 50.3623 343.875 50.3623C355.443 50.3623 364.862 54.4688 369.53 61.9303L358.618 70.8841C355.263 65.9415 350.034 63.2321 342.859 63.2321C328.116 63.2321 318.601 74.5248 318.601 88.6011C318.601 98.4863 325.131 105.016 336.879 105.016C343.504 105.016 349.378 102.688 354.226 97.4597L363.179 106.604C356.279 114.626 346.764 117.886 335.567 117.886C315.606 117.886 303.297 106.318 303.297 89.5325V89.5431Z"
          fill="#142445"
        />
        <g className={styles.hoverGroup}>
          <path
            d="M95.6757 88.4158L92.8817 102.598H123.193L120.865 114.356H90.458L87.4734 129.47H123.109L120.59 141.599H70.0315L83.0918 76.3081H132.433L129.914 88.437H95.6757V88.4158Z"
            fill="#142445"
          />
          <path
            d="M114.875 23.0616L90.363 -1.4502L88.913 5.85254L81.8008 41.4137L107.276 66.8886L142.869 43.0436L149.473 6.60398L149.515 5.84196L150.753 -0.984514L114.875 23.0616Z"
            fill="#142445"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_476_209">
          <rect width="446.964" height="164.591" fill="white" transform="translate(0.764404 0.570801)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
