import {
  arc,
  curveCatmullRomClosed,
  format,
  lineRadial,
  max,
  scaleBand,
  scaleLinear,
  timeDay,
  timeFormat,
  timeMonth,
} from 'd3';
import { useCallback, useMemo } from 'react';
import { DailyMeterpointUsage } from '../../../types/DailyMeterpointUsage';
import { MonthlyMeterpointUsage } from '../../../types/MonthlyMeterpointUsage';
import { MonthlyTemperature } from '../../../types/MonthlyTemperature';

type DateValue = [Date, number];

// Constants
const width = 1024;
const height = 1024;
const margin = 80;
const outerRadius = Math.min(width, height) / 1.8 - margin;
const innerRadius = Math.round(outerRadius * 0.3);

const usageSpringFill = '#97BE76';
const usageSummerFill = '#F87C7C';
const usageFallFill = '#FAD155';
const usageWinterFill = '#8BDDE9';

const potentialUsageStroke = '#212529';
const potentialUsageStrokeWidth = 2.5;
const potentialUsageStrokeDasharray = '1 4';
const potentialUsageStrokeLinecap = 'round';

const tickTextFontSize = 16;
const tickTextFill = '#212529';
const tickTextHaloStroke = '#fff';
const tickStroke = '#212529';
const tickStrokeOpacity = 0.3;

const formatXDateTick = timeFormat('%b');
const formatXTempTick = (d: number) => format('d')(d) + '℃';
const formatYTick = (d: number) => (d > 0 ? format('d')(d) + 'kwh' : '');

const accessors = {
  date: (d: DateValue) => d[0],
  value: (d: DateValue) => d[1],
};

const color = (date: Date) => {
  const month = date.getMonth();
  switch (month) {
    case 0:
      return usageWinterFill;
    case 1:
      return usageWinterFill;
    case 2:
      return usageSpringFill;
    case 3:
      return usageSpringFill;
    case 4:
      return usageSpringFill;
    case 5:
      return usageSummerFill;
    case 6:
      return usageSummerFill;
    case 7:
      return usageSummerFill;
    case 8:
      return usageFallFill;
    case 9:
      return usageFallFill;
    case 10:
      return usageFallFill;
    case 11:
      return usageWinterFill;
    default:
      return '';
  }
};

export default function ElectricityUsageChart({
  dailyConsumption,
  monthlyTemperature,
  monthlyConsumption,
}: {
  dailyConsumption?: DailyMeterpointUsage[];
  monthlyTemperature?: MonthlyTemperature[];
  monthlyConsumption?: MonthlyMeterpointUsage[];
}) {
  const { usage, temperature, hasPotentialUsage, potentialUsage } = useMemo(
    () => processData(monthlyConsumption, dailyConsumption, monthlyTemperature),
    [dailyConsumption, monthlyConsumption, monthlyTemperature]
  );

  const x = useMemo(
    () =>
      scaleBand<Date>()
        .domain(usage.map(accessors.date))
        .range([0, Math.PI * 2]),
    [usage]
  );

  const y = useMemo(
    () =>
      scaleLinear()
        .domain([0, max([...usage, ...potentialUsage], accessors.value) as number])
        .range([innerRadius, outerRadius])
        .nice(),
    [usage, potentialUsage]
  );

  const arcGenerator = useMemo(
    () =>
      arc<DateValue>()
        .innerRadius(() => y(0))
        .outerRadius((d) => y(accessors.value(d)))
        .startAngle((d) => x(accessors.date(d)) as number)
        .endAngle((d) => (x(accessors.date(d)) as number) + x.bandwidth())
        .padAngle(0.01)
        .padRadius(innerRadius),
    [x, y]
  );

  const lineGenerator = useMemo(
    () =>
      lineRadial<DateValue>()
        .angle((d) => x(accessors.date(d)) as number)
        .radius((d) => y(accessors.value(d)))
        .curve(curveCatmullRomClosed),
    [x, y]
  );

  const isFlipped = useCallback(
    (d: DateValue) => ((x(accessors.date(d)) as number) + x.bandwidth() / 2 + Math.PI / 2) % (2 * Math.PI) >= Math.PI,
    [x]
  );

  return (
    <div style={{ padding: '10px' }}>
      <svg
        viewBox={`${-width / 2 - margin},${-height / 2 - margin},${width + margin * 2},${height + margin * 2}`}
        style={{ display: 'block', width: '100%', height: 'auto' }}
      >
        {/* Usage */}
        <g>
          {usage.map((d, i) => (
            <path
              key={`usage-path-${i}`}
              fill={color(accessors.date(d))}
              stroke={color(accessors.date(d))}
              d={arcGenerator(d) || ''}
            ></path>
          ))}
        </g>

        {/* Potential Usage */}
        {hasPotentialUsage && (
          <path
            fill="none"
            stroke={potentialUsageStroke}
            strokeWidth={potentialUsageStrokeWidth}
            strokeDasharray={potentialUsageStrokeDasharray}
            strokeLinecap={potentialUsageStrokeLinecap}
            d={lineGenerator(potentialUsage) || ''}
          ></path>
        )}

        {/* Date Axis */}
        <g>
          {temperature.map((d, i) => (
            <g
              key={`date-tick-${i}`}
              transform={`rotate(${
                (((x(accessors.date(d)) as number) + x.bandwidth() / 2) * 180) / Math.PI - 90
              })translate(${innerRadius},0)`}
              fill={tickTextFill}
              fontSize={tickTextFontSize}
              textAnchor="middle"
            >
              <line x2={-6} stroke={tickStroke} strokeOpacity={tickStrokeOpacity}></line>
              <text
                transform={isFlipped(d) ? 'translate(-9,0)rotate(-90)' : 'translate(-9,0)rotate(90)'}
                dy={isFlipped(d) ? '0em' : '0.71em'}
              >
                <tspan>{formatXDateTick(accessors.date(d))}</tspan>
                <tspan x="0" dy={isFlipped(d) ? '-1.2em' : '1.2em'}>
                  {formatXTempTick(accessors.value(d))}
                </tspan>
              </text>
            </g>
          ))}
        </g>

        {/* Usage Axis */}
        <g>
          {y.ticks(5).map((d, i) => (
            <g key={`usage-tick-${i}`} fill="none" textAnchor="middle" fontSize={tickTextFontSize}>
              <circle stroke={tickStroke} strokeOpacity={tickStrokeOpacity} r={y(d)}></circle>
              <text
                fill={tickTextFill}
                stroke={tickTextHaloStroke}
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
                dy="0.32em"
                y={-y(d)}
              >
                {formatYTick(d)}
              </text>
              <text fill={tickTextFill} dy="0.32em" y={-y(d)}>
                {formatYTick(d)}
              </text>
            </g>
          ))}
        </g>
      </svg>
    </div>
  );
}

function zipDateValue(dates: Date[], values?: number[]) {
  const dateValueArray: DateValue[] = [];
  if (!values) return dateValueArray;
  for (let i = 0; i < Math.min(dates.length, values.length); i++) {
    const date = dates[i];
    const value = values[i];
    dateValueArray.push([date, value] as DateValue);
  }
  return dateValueArray;
}

function processData(
  monthlyConsumption?: MonthlyMeterpointUsage[],
  dailyConsumption?: DailyMeterpointUsage[],
  monthlyTemperature?: MonthlyTemperature[]
) {
  const accessors = {
    usage: (data?: DailyMeterpointUsage[]) => data?.map((d) => d.consumptionKwh),
    potentialUsage: (days: Date[], data?: MonthlyMeterpointUsage[]) => {
      return days
        .map((day) => {
          const daysInMonth = new Date(day.getFullYear(), day.getMonth(), 0).getDate();
          const monthlyReferenceValue =
            data?.find((mu) => mu.year === day.getFullYear() && mu.month === day.getMonth())?.referenceConsumptionKwh ||
            0;
          // const varianceMonthlyReferenceValue = monthlyReferenceValue + (Math.random() * monthlyReferenceValue / 40)
          return monthlyReferenceValue > 0 ? monthlyReferenceValue / daysInMonth : 0;
        })
        .reduce((prev, curr) => [...prev, curr], [] as number[]);
    },
    temperature: (data?: MonthlyTemperature[]) => data?.map((d) => d.averageTemperature),
  };

  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setDate(currentDate.getDate() - 365);

  const months = timeMonth.range(
    new Date(oneYearAgo.getFullYear(), oneYearAgo.getMonth(), oneYearAgo.getDate()),
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
  );

  const days = timeDay.range(
    new Date(oneYearAgo.getFullYear(), oneYearAgo.getMonth(), oneYearAgo.getDate()),
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
  );

  const usage = zipDateValue(days, accessors.usage(dailyConsumption));
  const potentialUsage = zipDateValue(days, accessors.potentialUsage(days, monthlyConsumption));
  const hasPotentialUsage = potentialUsage.find((pu) => pu[1] > 0) !== undefined;
  const temperature = zipDateValue(months, accessors.temperature(monthlyTemperature));

  return {
    usage,
    hasPotentialUsage,
    potentialUsage,
    temperature,
  };
}
