import { Box, Modal, TextField, useMediaQuery } from '@mui/material';
import React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { Button, Text, useMantineTheme } from '@mantine/core';
import { sendRecommendations } from '../../../api/users';
import { SCREEN_LIMIT } from '../../../utils/constants';

type ModalComponentProps = {
  opened: boolean;
  onClose: () => void;
  wideVersion: boolean;
};

export const AnbefalModal: React.FC<ModalComponentProps> = ({ opened, onClose, wideVersion }) => {
  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${SCREEN_LIMIT}px)`);
  const [emails, setEmails] = React.useState<string[]>([]);
  const [name, setName] = React.useState<string>('');

  const boxStyle = {
    width: isSmallScreen ? '75%' : '30%',
    backgroundColor: '#fff',
    p: 2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '6px',
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await sendRecommendations({ name, emails });
    onClose();
  };

  return (
    <Modal open={opened} onClose={onClose}>
      <Box sx={boxStyle}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
            <TextField
              margin={'normal'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoFocus={true}
              size={'medium'}
              fullWidth={true}
              label="Ditt navn"
              placeholder="Ditt navn"
              variant="outlined"
              required={true}
              name={'name'}
            />
            <ReactMultiEmail
              style={{ height: '3.2rem' }}
              placeholder={<div style={{ color: 'rgba(0,0,0,0.6)' }}>Eposter</div>}
              emails={emails}
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      x
                    </span>
                  </div>
                );
              }}
            />
          </div>
          <Text size="sm">En e-post med lenke og invitasjon sendes til dine bekjente</Text>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-32px', marginBottom: '-32px' }}>
            <Button
              disabled={emails.length < 1 || name.length < 2}
              type="submit"
              style={{
                minHeight: 52.5,
                borderRadius: '4px',
                color: '#fff',
                backgroundColor: emails.length < 1 ? 'grey' : theme.colors.green[0],
              }}
              variant="filled"
            >
              <Box p={2} height={50} width={150}>
                Send anbefaling
              </Box>
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};
