import { ApexOptions } from 'apexcharts';
import React from 'react';
import Card from './Card';
import SectionTitle from './InfoSections/SectionTitle';
import Paragraph from 'antd/es/typography/Paragraph';
import { useMantineTheme } from '@mantine/core';
import ReactApexChart from 'react-apexcharts';
import { MonthlyMeterpointUsage } from '../../types/MonthlyMeterpointUsage';
import { MonthlyTemperature } from '../../types/MonthlyTemperature';

export const SectionTempVsElectricity = ({
  chartType,
  drawInverted,
  monthlyConsumption,
  monthlyTemperature,
}: {
  chartType: string;
  drawInverted: boolean;
  monthlyConsumption: MonthlyMeterpointUsage[];
  monthlyTemperature: MonthlyTemperature[];
}) => {
  const theme = useMantineTheme();

  const usageColour = theme.colors.reduce[8];
  const temperatureColour = theme.colors.reduce[4];

  const today = new Date();
  const data = monthlyConsumption
    .filter((mc) => !(mc.year === today.getFullYear() && mc.month === today.getMonth()))
    .map((mc) => {
      return {
        ...mc,
        date: new Date(mc.year, mc.month, 1),
        averageTemperature: monthlyTemperature.find((t) => t.year === mc.year && t.month === mc.month)
          ?.averageTemperature,
      };
    });
  const series: ApexAxisChartSeries = [
    {
      name: 'Strømforbruk (kWh)',
      data: data.map((month) => month.consumptionKwh),
      type: chartType,
      color: usageColour,
    },
    /*{
      name: 'Sammenlignbar bolig (kWh)',
      data: data.map((month) => month.referenceConsumptionKwh || null),
      type: chartType,
      color: usageColour,
    },*/
    {
      name: 'Temperatur (°C)',
      data: data.map((month) => month.averageTemperature || null),
      type: chartType,
      color: temperatureColour,
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: chartType,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: true,
        offsetY: 8,
        style: {
          fontSize: '14px',
          fontFamily: 'Greycliff CF, sans-serif',
        },
      },
      categories: data.map(
        (mc) =>
          `${mc.date.toLocaleDateString('no-NB', { month: 'short' })} - ${mc.date.toLocaleDateString('no-NB', {
            year: '2-digit',
          })}`
      ),
    },
    yaxis: [
      {
        opposite: true,
        labels: {
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            colors: usageColour,
          },
          formatter: function (value: number) {
            return `${value?.toFixed(1) || value}`;
          },
        },
        tickAmount: 4,
      },
      {
        reversed: drawInverted,
        labels: {
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            colors: temperatureColour,
          },
          formatter: function (value: number) {
            return `${value?.toFixed(1) || value}`;
          },
        },
      },
    ],
    stroke: {
      show: chartType === 'line',
    },
  };

  return (
    <div>
      <section aria-describedby="overview">
        <SectionTitle
          title="Når strømforbruk og utetemperatur følger hverandre tett, har du mye å spare på ENØK"
          id="overview"
        />
        <Paragraph>
          Lavere utetemperatur på din adresse fører til høyere strømforbruk. Dette er vanlig - men når
          strømforbrukslinjen henger tett sammen med temperaturlinjen, tyder dette på at husets strømforbruk i stor grad
          påvirkes av utetemperatur. Da har du mye å spare på ENØK.
        </Paragraph>
        <Paragraph style={{ fontWeight: 700 }}>Dine forbruksdata fra de siste 12 månedene:</Paragraph>

        <Card>
          <ReactApexChart key={chartType} options={options} series={series} width="100%" />
        </Card>
      </section>
    </div>
  );
};
