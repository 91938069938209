import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Layout } from './Layout';
import { theme } from './theme';
import { StatusPage } from './pages/StatusPage';
import Complete from './pages/complete';
import EnergyReportPage from './pages/EnergyReportPage';
import { LoginPageAdmin } from './pages/LoginPageAdmin';
import { useAuth0 } from '@auth0/auth0-react';
import { REPORT_PATH } from './utils/constants';
import React from 'react';
import { OnboardingPage } from './pages/OnboardingPage';

export const App: React.FC = () => {
  const isAuthenticated = useAuth0().isAuthenticated;

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '',
          element: isAuthenticated ? <Navigate to="/status" replace /> : <Navigate to="/register" replace />,
        },
        {
          path: '/login',
          element: <LoginPageAdmin />,
        },
        {
          path: '/status',
          element: isAuthenticated ? <StatusPage /> : <LoginPageAdmin />,
        },
        {
          path: '/complete',
          element: <Complete />,
        },
        {
          path: REPORT_PATH + '/:id',
          element: <EnergyReportPage />,
        },
      ],
    },
    {
      path: '/register',
      element: <OnboardingPage />,
    },
  ]);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'DM Sans, sans-serif',
          },
        }}>
        <MantineProvider theme={theme}>
          <RouterProvider router={router} fallbackElement={<>Loading...</>} />
        </MantineProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};
