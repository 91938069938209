import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useMantineTheme } from '@mantine/core';
import { monthsNorwegian } from '../../../utils/constants';
import { MonthlyMeterpointUsage } from '../../../types/MonthlyMeterpointUsage';

interface ComparativeUsageChartMonthlyDataProps {
  chartType: string;
  yearlyConsumption: MonthlyMeterpointUsage[];
}

export const ComparedUsageChart: React.FC<ComparativeUsageChartMonthlyDataProps> = ({
  chartType,
  yearlyConsumption,
}) => {
  const theme = useMantineTheme();

  const uniqueYears = Array.from(new Set(yearlyConsumption.map((data) => data.year)));
  const dt = new Date();
  const currentYear = dt.getFullYear();
  const currentMonth = dt.getMonth();

  const series = uniqueYears.map((year, index) => {
    const yearData = yearlyConsumption.filter((data) => data.year === year);

    return {
      name: year.toString(),
      data: monthsNorwegian.map((month) => {
        const matchingData = yearData.find((data) => monthsNorwegian[data.month] === month);
        return matchingData
          ? matchingData.year === currentYear && matchingData.month === currentMonth
            ? 0 // Setter data for nåværende måned til 0 i diagram
            : matchingData.consumptionKwh
          : 0;
      }),
      type: chartType,
      color: year === currentYear ? theme.colors.chart[13] : theme.colors.chart[index + 10],
    };
  });

  const config = {
    options: {
      chart: {
        id: chartType,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: monthsNorwegian.map((month) => month.slice(0, 3)),
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 500,
          },
          formatter: function (value: number) {
            return value ? value.toFixed(0) : '';
          },
        },
      },
      stroke: {
        show: false,
      },
    },
    series: series,
  };

  return <ReactApexChart key={chartType} options={config.options} series={config.series} width="100%" />;
};
