import { StatusList } from '../components/Status/StatusList';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from '@mantine/core';

const StatusListComponent = (): JSX.Element => {
  return <StatusList />;
};

export const StatusPage = (): JSX.Element => {
  const Component = withAuthenticationRequired(StatusListComponent, {
    onRedirecting: () => <Loader />,
  });
  return <Component />;
};
