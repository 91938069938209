import Complete from '../components/Complete';

const CompletePage = () => {
  return (
    <div
      style={{
        marginBlock: 48,
        paddingInline: 32,
      }}
    >
      <Complete />
    </div>
  );
};

export default CompletePage;
