import { Button } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';

export const SignOutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      style={{ marginLeft: 'auto', marginRight: 20 }}
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
    >
      Sign out
    </Button>
  );
};
