import React, { useEffect, useState } from 'react';
import { MonthlyMeterpointUsage } from '../../../../types/MonthlyMeterpointUsage';
import { DailyMeterpointUsage } from '../../../../types/DailyMeterpointUsage';
import { YearCard } from '../YearCard';

interface TotalUsageGroupProps {
  yearlyConsumption: MonthlyMeterpointUsage[];
  dailyConsumption: DailyMeterpointUsage[];
}

export const TotalUsageGroup: React.FC<TotalUsageGroupProps> = ({ yearlyConsumption, dailyConsumption }) => {
  const currentYear = new Date().getFullYear();

  const years = Array.from(new Set(yearlyConsumption.map((data) => data.year)));
  const multipleYears = years.length > 1;

  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [percentageCalculated, setPercentageCalculated] = useState<boolean>(false);

  const calculateUpToCertainMonth = (data: MonthlyMeterpointUsage[], targetYear: number): number => {
    const filteredData = data.filter((item) => item.year === targetYear);
    const sumConsumptionKwh = (data: MonthlyMeterpointUsage[]): number => {
      return data.slice(0, filteredData.length).reduce((sum, item) => sum + item.consumptionKwh, 0);
    };
    return sumConsumptionKwh(data);
  };

  // Egen utregning for første år opptil gjeldende måned
  let usageLastYear = calculateUpToCertainMonth(dailyConsumption, years.length > 2 ? years[2] : years[1]);

  // Regner ut forbruk per år. Første = inneværende - 1.
  const sumOfFirstYear = yearlyConsumption
    .filter((entry) => entry.year === (years.length === 3 ? years[1] : years[0]))
    .reduce((sum, entry) => sum + entry.consumptionKwh, 0);

  const previousYearTotalValue = yearlyConsumption
    .filter((data) => data.year === (years.length === 3 ? years[2] : years[1]))
    .reduce((sum, data) => sum + data.consumptionKwh, 0);

  // Kalkulerer prosentendring, om nok data er tilstede
  useEffect(() => {
    if (!percentageCalculated && multipleYears && usageLastYear !== 0) {
      const calculatedPercentage = (previousYearTotalValue / usageLastYear - 1) * 100;
      setPercentageChange(previousYearTotalValue !== 0 ? calculatedPercentage : 0);
      setPercentageCalculated(true);
    }
  }, [percentageChange, percentageCalculated, previousYearTotalValue, usageLastYear, multipleYears]);

  const isFinalYear = (y: number) => {
    return y === years[years.length - 1];
  };

  // Viser kun bokser med inneværende og forrige år.
  const renderYearCards = years
    .filter((year) => years.length !== 3 || year !== years[0])
    .map((year) => {
      return (
        <div key={year}>
          <YearCard
            year={year}
            isFinalYear={isFinalYear(year)}
            previousYearTotalValue={previousYearTotalValue}
            sumOfFirstYear={sumOfFirstYear}
            percentageChange={percentageChange}
            multipleYears={years.length > 1}
            currentYear={currentYear}
          />
        </div>
      );
    });
  return <>{renderYearCards}</>;
};
