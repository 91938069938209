import { Group, Text } from '@mantine/core';
import { InfoFrame } from '../components/Onboarding/InfoFrame';
import { Register } from '../components/Onboarding/Register/Register';
import './onboardingPage.css';
import React, { useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { SCREEN_LIMIT } from '../utils/constants';
import { DropdownInfo } from '../components/Onboarding/Accordion/DropdownInfo';

export const OnboardingPage = () => {
  document.body.style.backgroundColor = '#fff';

  const isSmallScreen = useMediaQuery(`(max-width: ${SCREEN_LIMIT}px)`);
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const focusReference = () => {
    if (accordionRef.current) {
      accordionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="register-container">
      <Group
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          paddingLeft: isSmallScreen ? 'calc(var(--mantine-spacing-md))' : 'calc(var(--mantine-spacing-xl)*2)',
        }}
      >
        <Register toggleOpen={toggleOpen} focusReference={focusReference} />
        {!isSmallScreen && (
          <div ref={accordionRef}>
            <DropdownInfo isOpen={isOpen} toggleOpen={toggleOpen} />
            <Text className="product-footer" style={{ marginTop: 'calc(var(--mantine-spacing-md))' }}>
              @2024 - Et Unbolt AS produkt
            </Text>
          </div>
        )}
      </Group>
      <InfoFrame isSmallScreen={isSmallScreen} />
      {isSmallScreen && (
        <div ref={accordionRef}>
          <DropdownInfo isOpen={isOpen} toggleOpen={toggleOpen} />
          <Text className="product-footer" style={{ marginTop: 'calc(var(--mantine-spacing-md))' }}>
            @2024 - Et Unbolt AS produkt
          </Text>
        </div>
      )}
    </div>
  );
};
