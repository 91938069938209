import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { App } from './App';
import './main.css';
import { authConfig } from './authentication/authConfig';
import { AUTH0_AUDIENCE, SENTRY_DSN, SENTRY_ENVIRONMENT } from './utils/constants';

ReactGA.initialize([{ trackingId: 'G-LYJ0MNET9W' }]);

const isDevelopment = process.env.NODE_ENV === 'development';
if (!isDevelopment) {
  LogRocket.init('qqad7s/reduce-frontend');
}

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT || 'development',
    integrations: [
      new Sentry.BrowserTracing({}),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const redirectUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port?.length ? `:${window.location.port}` : ''
}`;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: redirectUrl,
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
