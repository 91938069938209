export const REDUCE_API_BASEURL = process.env.REACT_APP_REDUCE_API_BASEURL || '';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || '';
export const REDUCE_ENERGY_URL = process.env.REACT_APP_REDUCE_ENERGY_URL || '';
export const ELHUB_AUTH_URL = process.env.REACT_APP_ELHUB_AUTH_URL || '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const REPORT_PATH = '/energy_report';

/**
 * Register
 */
export const REDUCE_AVTALEVILKAR = 'https://reduceenergy.no/index.php/avtalevilkar/';
export const REDUCE_PERSONVERN = 'https://reduceenergy.no/index.php/personvernerklaering/';
export const REGISTER_HVORDAN = 'Hvordan fungerer det?';
export const REDUCE_ANBEFAL =
  'mailto:?subject=Sjekk%20ut%20Reduce%20Energy&body=Hei%21%0D%0A%0D%0AJeg%20vil%20dele%20denne%20spennende%20muligheten%20med%20deg.%20Besøk%20https%3A%2F%2Fapp.reduceenergy.no%2Fregister%20for%20å%20komme%20i%20gang.%0D%0A%0D%0AHa%20en%20fin%20dag%21';
export const SCREEN_LIMIT = 900;

/**
 * Charts
 */
export const monthsNorwegian: string[] = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];

/**
 * Energy report
 */
export const fridge_usage_note = `**Based on an Energy A class fridge POINT 3-SERIES RFI376E KOMBISKAP`;
export const fridge_kwh_usage_per_year = 218;
export const heat_pump_kwh_usage_per_year = (10 * 365) / 2;

/**
 * Info
 */
export const POPUP_INFO_BETA =
  'Vi lanserer nå første versjon av Reduce sin energianalyse. Siden tjenesten fortsatt er under utvikling, er den gratis for de første som melder seg opp.';

export const INFO_FRAME_ITEMS = [
  {
    id: '1',
    text: 'Norges totale energiforbruk var fra 51,7% fornybare kilder i 2022',
    source: 'https://www.tilnull.no/energibruk',
  },
  {
    id: '2',
    text: 'Solcellepanel på en enebolig kan dekke inntil 25% av strømforbruket til boligen',
    source: 'https://solceller.no/hva-er-solcellepanel',
  },
  {
    id: '3',
    text: 'I gjennomsnitt brukte en norsk husholdning ca. 15.000 kWh med strøm i 2022',
    source:
      'https://www.ssb.no/energi-og-industri/energi/artikler/hva-er-gjennomsnittlig-stromforbruk-i-husholdningene',
  },
  {
    id: '4',
    text: 'Strømforbruket i norske husholdninger er beregnet til å være fra 98,3% fornybare kilder',
    source: 'https://www.tilnull.no/energibruk/husholdninger',
  },

  {
    id: '5',
    text: 'Gjennomsnittlig strømpris i 2022 var 143,9 øre/kWh etter strømstøtte',
    source:
      'https://www.ssb.no/energi-og-industri/energi/statistikk/elektrisitetspriser/artikler/rekordhoy-strompris-i-2022--dempet-av-stromstotte',
  },
];
