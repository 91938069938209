import { Typography } from 'antd';
import Card from '../Card';
import SectionTitle from '../InfoSections/SectionTitle';
import { ComparedUsageChart } from './ComparedUsageChart';
import { TotalUsageGroup } from './TotalUsageGroup';
import { Grid, SimpleGrid } from '@mantine/core';
import { MonthlyMeterpointUsage } from '../../../types/MonthlyMeterpointUsage';
import { DailyMeterpointUsage } from '../../../types/DailyMeterpointUsage';
const { Paragraph } = Typography;

interface SectionMonthlyPowerConsumptionProps {
  yearlyConsumption: MonthlyMeterpointUsage[];
  dailyConsumption: DailyMeterpointUsage[];
}

export const SectionMonthlyPowerConsumption: React.FC<SectionMonthlyPowerConsumptionProps> = ({
  yearlyConsumption,
  dailyConsumption,
}) => {
  return yearlyConsumption.length !== 0 ? (
    <section aria-describedby="overview">
      <SectionTitle title="Hvordan ser strømforbruket ditt ut?" id="monthlyPowerConsumption" />

      <Paragraph>
        Grafen nedenfor viser ditt månedlige strømforbruk over tilgjengelig periode, i kilowatt-timer. I tillegg vil du
        se ditt summerte strømforbruk per år i boksene ved siden av grafen. Prosenten vil vise deg om du har brukt mer
        eller mindre strøm per år.
      </Paragraph>

      <br />
      <div>
        <Grid>
          <Grid.Col span={12}>
            <Card>
              <ComparedUsageChart chartType="column" yearlyConsumption={yearlyConsumption} />
            </Card>
          </Grid.Col>
        </Grid>
        <SimpleGrid cols={{ base: 1, sm: 3 }} style={{ marginTop: 14 }}>
          <TotalUsageGroup yearlyConsumption={yearlyConsumption} dailyConsumption={dailyConsumption} />
        </SimpleGrid>
      </div>
    </section>
  ) : (
    <></>
  );
};
