import { useAuth0 } from '@auth0/auth0-react';
import { Button, Loader, useMantineTheme } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMeterpoints, sendReportReadyEmail, updateMeterpoint } from '../../api/admin';
import { Meterpoint } from '../../types/Meterpoint';
import { REPORT_PATH } from '../../utils/constants';
import Error from '../Error';
import { useDisclosure } from '@mantine/hooks';
import { MatchingModal } from './MatchingModal';

export type Record = Meterpoint & { statusName: string };

export const StatusList: React.FC = () => {
  useEffect(() => {
    document.title = 'Admin - Reduce AS';
  });

  const theme = useMantineTheme();
  const { getAccessTokenSilently } = useAuth0();
  const defaultWidth = 100;

  const [opened, { open, close }] = useDisclosure(false);
  const [matchingData, setMatchingData] = useState<Meterpoint | undefined>();

  // noinspection JSIgnoredPromiseFromCall
  getAccessTokenSilently();
  const userAuthenticated = useAuth0().isAuthenticated;

  const {
    isLoading,
    isError,
    data: meterpoints,
    refetch: refetchMeterpoints,
  } = useQuery<Meterpoint[]>({
    queryKey: ['meterpoints'],
    queryFn: async () => {
      const accessToken = getAccessTokenSilently();
      return userAuthenticated ? getMeterpoints(await accessToken) : [];
    },
  });

  const { mutate: mutateMeterpoint, isLoading: isMutateMeterpointLoading } = useMutation({
    mutationFn: async (meterpointId: string) => {
      const accessToken = await getAccessTokenSilently();
      await updateMeterpoint(accessToken, meterpointId);
      return refetchMeterpoints();
    },
  });

  const matchUser = (meterpoint: Meterpoint) => {
    setMatchingData(meterpoint);
    open();
  };

  const { mutate: sendEmail, isLoading: isSendingEmail } = useMutation({
    mutationFn: async (meterpointId: string) => {
      const accessToken = await getAccessTokenSilently();
      return await sendReportReadyEmail(accessToken, meterpointId);
    },
  });
  console.log('isSendingEmail', isSendingEmail);

  const formatNumber = (v?: number) => {
    return v
      ? new Intl.NumberFormat('no-NB', {
          maximumFractionDigits: 0,
          currencySign: undefined,
          currency: 'NOK',
          style: 'currency',
        })
          .format(v)
          .replaceAll('kr', '')
          .trim()
      : undefined;
  };

  const columns: ColumnsType<Meterpoint> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: defaultWidth * 0.5,
    },
    {
      title: 'Meter ID',
      width: defaultWidth * 1.25,
      sorter: (a, b) => a.meterpointId.localeCompare(b.meterpointId),
      render: (_text: string, m: Meterpoint) => {
        return m.hasMeterpointValues ? (
          <Link to={`https://app.reduceenergy.no${REPORT_PATH}/${m.uuid}`} target="_blank">
            {m.meterpointId}
          </Link>
        ) : (
          <span>{m.meterpointId}</span>
        );
      },
    },
    {
      title: '',
      width: defaultWidth * 0.8,
      render: (_text, m) => (
        <Button
          disabled={isMutateMeterpointLoading}
          onClick={() => mutateMeterpoint(m.meterpointId)}
          title={
            '1. Henter data fra Elporten \n2. matcher temp sensor på nytt \n3. matcher bruksenhet hvis ikke matchet fra før\n4. matcher bruker hvis ikke matchet fra før'
          }
        >
          {isMutateMeterpointLoading ? 'Oppdaterer...' : 'Oppdater'}
        </Button>
      ),
    },
    {
      title: '',
      width: defaultWidth * 0.9,
      render: (_text, m) => (
        <Button
          disabled={isSendingEmail}
          onClick={() => sendEmail(m.meterpointId)}
          title={'Sender rapportepost til bruker'}
        >
          {isSendingEmail ? 'Sender...' : 'Send epost'}
        </Button>
      ),
    },
    {
      title: 'Sist epost sendt',
      width: defaultWidth,
      sorter: (a, b, sortOrder) => `${a.lastEmailSent?.sentAt}`.localeCompare(`${b.lastEmailSent?.sentAt}`),
      ellipsis: {
        showTitle: true,
      },
      render: (_text: string, m: Meterpoint) =>
        m.lastEmailSent
          ? `${new Date(m.lastEmailSent.sentAt).toLocaleDateString('NO-nb', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })} ${new Date(m.lastEmailSent.sentAt).toLocaleTimeString('NO-nb', {
              hour: '2-digit',
              minute: '2-digit',
            })}`
          : 'Ikke sendt',
    },
    {
      title: 'Mottatt',
      width: defaultWidth,
      sorter: (a, b, sortOrder) => `${a.createdAt}`.localeCompare(`${b.createdAt}`),
      ellipsis: {
        showTitle: true,
      },
      render: (_text: string, m: Meterpoint) =>
        `${new Date(m.createdAt).toLocaleDateString('NO-nb', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })} ${new Date(m.createdAt).toLocaleTimeString('NO-nb', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })}`,
    },
    {
      title: 'Matchet bruker',
      width: defaultWidth * 1.1,
      render: (_text, m) => (
        <>
          <span>{m.userMatchType}</span>
          <Button onClick={() => matchUser(m)}>
            {m.userMatchType && m.userMatchType === 'NO_MATCH' ? <span>Match bruker</span> : <span>Endre match</span>}
          </Button>
        </>
      ),
    },
    {
      title: 'Målerdata lastet',
      width: defaultWidth * 0.7,
      render: (_text: number, m: Meterpoint) => (m.hasMeterpointValues ? 'Ja' : 'Nei'), // statusMap[text] || text, // Render status text based on the status code
    },
    {
      title: 'Adresse',
      width: defaultWidth * 2,
      dataIndex: 'completeAddress',
      sorter: (a, b) => a.completeAddress?.localeCompare(b.completeAddress),
    },
    {
      title: 'Fornavn',
      width: defaultWidth,
      render: (_text: any, m: Meterpoint) => m.user?.firstName,
      sorter: (a, b) => a.user?.firstName?.localeCompare(b.user?.firstName || '') || 0,
    },
    {
      title: 'Etternavn',
      width: defaultWidth,
      render: (_text: any, m: Meterpoint) => m.user?.lastName,
      sorter: (a, b) => a.user?.lastName?.localeCompare(b.user?.lastName || '') || 0,
    },
    {
      title: 'Epost',
      width: defaultWidth * 1.5,
      render: (_text: any, m: Meterpoint) => m.user?.email,
      sorter: (a, b) => a.user?.email?.localeCompare(b.user?.email || '') || 0,
    },
    {
      title: 'Lat.',
      width: defaultWidth * 0.7,
      render: (_text: any, m: Meterpoint) => m.resolvedAddressLocation?.lat,
    },
    {
      title: 'Long.',
      width: defaultWidth * 0.7,
      render: (_text: any, m: Meterpoint) => m.resolvedAddressLocation?.lon,
    },
    {
      title: 'Distanse temp sensor',
      width: defaultWidth * 0.7,
      render: (_text: any, m: Meterpoint) =>
        m.temperatureSensorDistance ? `${formatNumber(m.temperatureSensorDistance)} m` : '',
      sorter: (a, b) => {
        if (a.temperatureSensorDistance === undefined) return -1;
        if (b.temperatureSensorDistance === undefined) return 1;
        return a.temperatureSensorDistance > b.temperatureSensorDistance ? 1 : -1;
      },
    },
    {
      title: 'Postnr.',
      render: (_text: any, m: Meterpoint) =>
        m.postalCode
          ? `${
              m.postalCode < 1000 ? (m.postalCode < 100 ? `00${m.postalCode}` : `0${m.postalCode}`) : `${m.postalCode}`
            }`
          : '',
      width: defaultWidth * 0.5,
    },
    {
      title: 'By',
      width: defaultWidth,
      dataIndex: 'city',
      sorter: (a, b) => a.city?.localeCompare(b.city || '') || 0,
    },
  ];

  if (isLoading) return <Loader color={theme.colors.teal[4]} />;
  if (isError) return <Error />;

  return (
    <>
      <Table
        bordered
        dataSource={meterpoints}
        columns={columns}
        rowClassName="editable-row"
        rowKey={(record) => `${record.id}`}
        pagination={false}
        sticky
        scroll={{ x: 2400, y: 800 }}
      ></Table>
      <MatchingModal opened={opened} onClose={close} meterpoint={matchingData} />
    </>
  );
};
