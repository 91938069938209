import styles from './styles.module.css';

function SectionTitle({ title, id }: { title: string; id: string }) {
  return (
    <h2 className={styles.header} id={id}>
      {title}
    </h2>
  );
}

export default SectionTitle;
