import clsx from 'clsx';
import styles from './styles.module.css';

import { useState } from 'react';

type Props = {
  header: string;
  children: React.ReactNode;
};

const Caret = () => (
  <div className={styles.caret}>
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.924073 1.16113C0.924073 1.16113 3.13352 4.16113 3.92407 4.16113C4.71467 4.16114 6.92407 1.16113 6.92407 1.16113"
        stroke="#142445"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const Accordion = ({ header, children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={clsx(styles.container, open && styles.open)}>
      <h3 className={styles.header} onClick={() => setOpen(!open)}>
        {header} <Caret />
      </h3>
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
