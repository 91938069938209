import styles from './styles.module.css';

import { Col, Row, theme } from 'antd';
import { DailyMeterpointUsage } from '../../types/DailyMeterpointUsage';
import { Meterpoint } from '../../types/Meterpoint';
import { MonthlyMeterpointUsage } from '../../types/MonthlyMeterpointUsage';
import { MonthlyTemperature } from '../../types/MonthlyTemperature';
import { SectionTempVsElectricity } from './SectionTempVsElectricity';
import ReportTitle from './InfoSections/ReportTitle';
import { SectionMonthlyPowerConsumption } from './MonthlyUsage/SectionMonthlyPowerConsumption';
import { SectionTips } from './InfoSections/SectionTips';
import { SectionUsageCircle } from './UsageCircle/SectionUsageCircle';

const { useToken } = theme;

type SectionProps = {
  children: React.ReactNode;
  hasDivider?: boolean;
};
const Section = ({ children, hasDivider = true }: SectionProps) => {
  const { token } = useToken();
  return (
    <>
      <Row justify="center">
        <Col
          span={24}
          md={20}
          lg={18}
          xl={16}
          xxl={12}
          style={{
            paddingInline: token.paddingContentHorizontal,
          }}>
          <article>{children}</article>
        </Col>
      </Row>
      {hasDivider && <hr className={styles.divider} />}
    </>
  );
};

function EnergyReport({
  meterpoint,
  yearlyConsumption,
  monthlyConsumption,
  dailyConsumption,
  dailyComparedConsumption,
  monthlyTemperature,
}: {
  meterpoint?: Meterpoint;
  yearlyConsumption?: MonthlyMeterpointUsage[];
  monthlyConsumption?: MonthlyMeterpointUsage[];
  dailyConsumption?: DailyMeterpointUsage[];
  dailyComparedConsumption?: DailyMeterpointUsage[];
  monthlyTemperature?: MonthlyTemperature[];
}) {
  return (
    <>
      {meterpoint && (
        <>
          <Section>
            <ReportTitle meterpoint={meterpoint} />

            {/* "drawInverted" settes til false for å vertikalt invertere visning av temperaturmåling */}
            {monthlyConsumption && monthlyTemperature && (
              <SectionTempVsElectricity
                chartType="line"
                drawInverted
                monthlyConsumption={monthlyConsumption}
                monthlyTemperature={monthlyTemperature}
              />
            )}
          </Section>

          {yearlyConsumption && dailyComparedConsumption && (
            <Section>
              <SectionMonthlyPowerConsumption
                yearlyConsumption={yearlyConsumption}
                dailyConsumption={dailyComparedConsumption}
              />
            </Section>
          )}

          {dailyConsumption && monthlyTemperature && monthlyConsumption && (
            <Section>
              <SectionUsageCircle
                dailyConsumption={dailyConsumption}
                monthlyTemperature={monthlyTemperature}
                monthlyConsumption={monthlyConsumption}
              />
            </Section>
          )}
        </>
      )}
      <Section hasDivider={false}>
        <SectionTips />
      </Section>
    </>
  );
}

export default EnergyReport;
