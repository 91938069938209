import axios from 'axios';
import { UserId } from '../types/User';
import { REDUCE_API_BASEURL } from '../utils/constants';

export const sendRecommendations = async (params: { name: string; emails: string[] }) => {
  try {
    return (await axios.post(`${REDUCE_API_BASEURL}/users/recommend`, params)).data;
  } catch (error) {
    console.error('Error sending recommendations:', error);
    throw error;
  }
};

export const registerUser = async (params: {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  // bruksenhetId: number;
}) => {
  try {
    const user = (await axios.post<UserId>(`${REDUCE_API_BASEURL}/users/create`, params)).data;
    return { alreadyRegistered: false, user };
  } catch (error: any) {
    if (error?.response?.status === 409) return { alreadyRegistered: true, user: error.response.data as UserId };
    console.error('Error creating user:', error);
    throw error;
  }
};
