import { Button, Space, useMantineTheme } from '@mantine/core';
import { Box, Modal } from '@mui/material';
import React from 'react';
import ReactGA from 'react-ga4';
import { ELHUB_AUTH_URL } from '../../../utils/constants';

type ModalComponentProps = {
  opened: boolean;
  onClose: () => void;
  numberUsed: boolean;
  userUuid: string;
  wideVersion: boolean;
};

export const RoutingModal: React.FC<ModalComponentProps> = ({ opened, onClose, userUuid, numberUsed, wideVersion }) => {
  const theme = useMantineTheme();
  const handleClicked = () => {
    ReactGA.event({
      category: 'register',
      action: 'navigate_to_elhub',
    });
    window.location.replace(`${ELHUB_AUTH_URL}&request_id=${userUuid}`);
    onClose();
  };

  const boxStyle = {
    width: wideVersion ? '90%' : '35%',
    backgroundColor: '#fff',
    p: 2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '6px',
  };

  return (
    <Modal open={opened} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Box sx={boxStyle}>
          <div style={{ marginLeft: 'calc(var(--mantine-spacing-xl))' }}>
            {numberUsed ? (
              <>
                <h2 style={{ fontWeight: 700 }}>Fant bruker med samme telefonnummer</h2>
                <Space h="md" />
                <p style={{ fontSize: '14px' }}>
                  Hvis dette er ditt telefonnummer, og du ikke er ferdig med onboardingen hos Elhub, <br />
                  vennligst trykk på lenken under for å fortsette.
                </p>
              </>
            ) : (
              <>
                <h2 style={{ color: theme.colors.reduce[8], fontWeight: 700 }}>
                  Du blir nå sendt til Elhub.no for
                  <br />
                  godkjenning med BankID
                </h2>
                <Space h="md" />
                <p style={{ color: theme.colors.reduce[8], fontSize: '14px', fontWeight: 400 }}>
                  Vi får tilgang til ditt historiske strømforbruk via ElHub.no - derfor må du godkjenne dette hos Elhub.
                  <br />
                  <br />
                  Når du er ferdig med BankID prosessen lander du på ElHub.no og kan lukke vinduet.
                  <br />
                  <br />
                  Energianalysen vil bli sendt til din epost innen 24 timer.
                  <br />
                </p>
              </>
            )}
          </div>
          <Space h="xl" />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleClicked}
              style={{
                minHeight: 50,
                borderRadius: '4px',
                color: '#fff',
                backgroundColor: theme.colors.green[0],
              }}
              variant="filled"
            >
              <Box p={2} height={50} width={150}>
                Start godkjenning
              </Box>
            </Button>
          </div>
          {numberUsed ? (
            <>
              <Space h="lg" />
              <p>Du kan og lukke denne boksen og skrive et nytt telefonnummer om ønskelig.</p>
              <Space h="xs" />{' '}
            </>
          ) : (
            <Space h="sm" />
          )}
        </Box>
      </div>
    </Modal>
  );
};
