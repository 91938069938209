import { theme, Typography } from 'antd';
import { Meterpoint } from '../../../../types/Meterpoint';
import styles from './styles.module.css';

const { Title, Paragraph } = Typography;
const { useToken } = theme;

const InfoNotification = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.infoContainer}>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
      >
        <path
          d="M15.5833 8.49984C15.5833 4.58782 12.412 1.4165 8.5 1.4165C4.58798 1.4165 1.41667 4.58782 1.41667 8.49984C1.41667 12.4118 4.58798 15.5832 8.5 15.5832C12.412 15.5832 15.5833 12.4118 15.5833 8.49984Z"
          stroke="#929292"
        />
        <path
          d="M8.67156 12.0415V8.49984C8.67156 8.16593 8.67156 7.99898 8.56779 7.8952C8.46409 7.7915 8.29713 7.7915 7.96323 7.7915"
          stroke="#929292"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8.49433 5.6665H8.50333" stroke="#929292" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      {children}
    </div>
  );
};

function ReportTitle({ meterpoint }: { meterpoint: Meterpoint }) {
  const { token } = useToken();
  const sensorDistance = meterpoint.temperatureSensorDistance ? meterpoint.temperatureSensorDistance / 1000.0 : 0;
  const boligtype = meterpoint.bruksenhet?.bygningstypeKategori;
  const bruksareal = meterpoint.bruksenhet?.bruksareal;
  const byggeaar = meterpoint.bruksenhet?.byggTattIBrukDato;
  return (
    <>
      <hgroup>
        <Title style={{ fontSize: 40, marginBottom: 6, fontWeight: 400 }}>Energirapport</Title>
        <Paragraph
          style={{
            lineHeight: token.lineHeightHeading3,
            marginBottom: token.marginXS,
            marginTop: token.marginXS,
            color: '#929292',
            fontWeight: 700,
          }}
        >
          <span style={{ minWidth: '100px', display: 'inline-block' }}>MålerpunktId:</span>
          {meterpoint.meterpointId}
          <br />
          <span style={{ minWidth: '100px', display: 'inline-block' }}>Adresse:</span>
          {meterpoint.completeAddress}
          {boligtype && (
            <div style={{ marginTop: '2px' }}>
              <span
                style={{
                  minWidth: '100px',
                  display: 'inline-block',
                }}
              >
                Boligtype:
              </span>
              {boligtype} {bruksareal && <>- {bruksareal} kvm</>}
            </div>
          )}
          {byggeaar && byggeaar.length >= 4 && (
            <>
              <span style={{ minWidth: '100px', display: 'inline-block' }}>Byggeår:</span>
              {byggeaar.substring(0, 4)}
            </>
          )}
        </Paragraph>
        <InfoNotification>
          Analysen er basert på temperaturmålinger fra sensor {sensorDistance?.toFixed(1)}km fra boligen din
        </InfoNotification>
      </hgroup>
    </>
  );
}

export default ReportTitle;
