import React from 'react';
import { Card } from 'antd';

interface StepProps {
  step: {
    id: number;
    image: string;
    description: any;
  };
}

const Step: React.FC<StepProps> = ({ step }) => {
  return (
    <div className="step">
      <Card className="step-description" title={step.description} size="small">
        <img
          src={step.image}
          alt={`Step ${step.id}`}
          className="step-image"
          style={{
            maxWidth: '75%',
            height: 'auto',
            marginBottom: '30px',
          }}
        />{' '}
      </Card>
      <br></br>
      <br></br>
    </div>
  );
};

export default Step;
