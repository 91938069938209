import axios from 'axios';
import { REDUCE_API_BASEURL } from '../utils/constants';
import { Meterpoint } from '../types/Meterpoint';
import { MatchingUser } from '../types/MatchingUser';
import { User } from '../types/User';

export const getMeterpoints = async (token: string) => {
  try {
    return (
      await axios.get<Meterpoint[]>(`${REDUCE_API_BASEURL}/admin/meterpoints`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
  } catch (error) {
    console.error('Error getting meterpoints:', error);
    throw error;
  }
};

export const updateMeterpoint = async (token: string, meterpointId: string) => {
  try {
    return (
      await axios.put<Meterpoint>(`${REDUCE_API_BASEURL}/admin/meterpoints/${meterpointId}/update`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
  } catch (error) {
    console.error('Error updating meterpoint:', error);
    throw error;
  }
};

export const sendReportReadyEmail = async (token: string, meterpointId: string) => {
  await new Promise((resolve) => setTimeout(() => resolve(null), 2000)); // TODO find a better way to make frontend show state change :)
  try {
    return (
      await axios.put<Meterpoint>(`${REDUCE_API_BASEURL}/admin/meterpoints/${meterpointId}/sendreportemail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const getAllUsers = async (token: string) => {
  try {
    return (
      await axios.get<MatchingUser[]>(`${REDUCE_API_BASEURL}/admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
  } catch (error) {
    console.error('Error getting users:', error);
    throw error;
  }
};

export const matchMeterpointWithUser = async (token: string, userId: string, meterpointId: string) => {
  await new Promise((resolve) => setTimeout(() => resolve(null), 2000));
  try {
    return (
      await axios.put<User>(
        `${REDUCE_API_BASEURL}/admin/users/${userId}/match_meterpoint/${meterpointId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ).data;
  } catch (error) {
    console.error('Error matching user with meterpoint:', error);
    throw error;
  }
};
