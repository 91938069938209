import { Image, Text } from '@mantine/core';
import Carousel from 'react-material-ui-carousel';
import './infoFrame.css';
import { INFO_FRAME_ITEMS } from '../../utils/constants';

export const InfoFrame = ({ isSmallScreen }: { isSmallScreen: boolean }) => {
  const animationMs = 1000;
  const intervalMs = 10000;

  return (
    <div style={{ margin: '6px', borderRadius: '6px', position: 'relative' }}>
      <Image className="image-container" radius={12} fit="cover" src="images/frame_1.png" />
      <div
        style={{
          position: 'absolute',
          minWidth: isSmallScreen ? 350 : 450,
          textAlign: 'left',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff',
        }}
      >
        <Text>Energifakta</Text>
        <Carousel
          autoPlay
          stopAutoPlayOnHover
          indicators={false}
          navButtonsAlwaysInvisible
          interval={intervalMs}
          duration={animationMs}
        >
          {INFO_FRAME_ITEMS.map((item) => (
            <Text
              key={item.id}
              style={{
                fontSize: !isSmallScreen ? 30 : 18,
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: 'normal',
                marginTop: 'calc(var(--mantine-spacing-sm))',
                height: !isSmallScreen ? 120 : 100,
              }}
            >
              {item.text}
            </Text>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
