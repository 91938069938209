export const isLeapYear = (year: number) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const isValidEmail = (value: string) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return expression.test(value);
};

export const isValidPhoneNumber = (value: string) => {
  return !isNaN(Number(value)) && value.trim().length === 8 && (value.startsWith('4') || value.startsWith('9'));
};
