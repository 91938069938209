import { Checkbox, Group, Space, Title, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { Box, Button, Link, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { registerUser } from '../../../api/users';
import {
  POPUP_INFO_BETA,
  REDUCE_AVTALEVILKAR,
  REDUCE_PERSONVERN,
  REGISTER_HVORDAN,
  SCREEN_LIMIT,
} from '../../../utils/constants';
import { isValidEmail, isValidPhoneNumber } from '../../../utils/utilConst';
import { RoutingModal } from './RoutingModal';
import './registerStyle.css';

interface RegisterProps {
  toggleOpen: () => void;
  focusReference: () => void;
}

export const Register: React.FC<RegisterProps> = ({ toggleOpen, focusReference }) => {
  const theme = useMantineTheme();
  useEffect(() => {
    document.title = 'Registrer deg - Reduce AS';
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [registerError, setRegisterError] = useState<string | undefined>();
  const [userUuid, setUserUuid] = useState<string | undefined>();
  const [isMobileConflictError, setIsMobileConflictError] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      terms: false,
      person: false,
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: {
      firstName: (value) => (value.length < 2 ? `Fornavn må være minst 2 bokstaver langt` : null),
      lastName: (value) => (value.length < 2 ? `Etternavn må være minst 2 bokstaver langt` : null),
      email: (value) => (!isValidEmail(value) ? `Ugyldig epost` : null),
      mobile: (value) => (!isValidPhoneNumber(value) ? `Ugyldig mobilnummer` : null),
      terms: (value) => (!value ? 'Vilkår må aksepteres for å fortsette' : false),
      person: (value) => (!value ? 'Behandling må aksepteres for å fortsette' : false),
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.isValid()) {
      try {
        await storeUserData();
        open();
      } catch (_) {
        //Ignored, handled by state
      }
    }
  };

  const handleRoutingModalClose = () => {
    setUserUuid(undefined);
    setRegisterError(undefined);
    setIsMobileConflictError(false); // Tilbakestiller error for å kunne "Gå videre" med ny info
  };

  const storeUserData = async () => {
    // if (enhetValue > 0) {
    setRegisterError(undefined);
    try {
      const result = await registerUser({
        firstName: form.getInputProps('firstName').value,
        lastName: form.getInputProps('lastName').value,
        mobile: form.getInputProps('mobile').value,
        email: form.getInputProps('email').value,
      });
      setUserUuid(result.user.uuid);
      ReactGA.event({
        action: 'sign_up',
        category: result.alreadyRegistered ? 'mobile_conflict' : 'success',
      });
      if (result.alreadyRegistered) {
        setIsMobileConflictError(true);
        setRegisterError('Det finnes allerede en bruker med dette mobilnummeret');
      }
    } catch (e) {
      setRegisterError('Det skjedde en uforventet feil under registrering, vennligst prøv igjen');
      throw new Error('Det skjedde en uforventet feil under registrering, vennligst prøv igjen');
    }
  };

  const isSmallScreen = useMediaQuery(`(max-width: ${SCREEN_LIMIT}px)`);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Group
        style={{ marginTop: 'calc(var(--mantine-spacing-xl))', marginBottom: 'calc(var(--mantine-spacing-xl)*1.5)' }}
      >
        <img src="/reduce_logo.png" alt="Reduce AS" style={{ height: 50, marginLeft: '-16px', marginRight: '-16px' }} />
        <Tooltip title={<Typography sx={{ fontSize: 16 }}>{POPUP_INFO_BETA}</Typography>}>
          <Button
            style={{ fontWeight: 700, color: '#fff', backgroundColor: theme.colors.reduce[8] }}
            onClick={() => window.open('https://reduceenergy.no')}
          >
            Beta
          </Button>
        </Tooltip>
      </Group>
      <form onSubmit={(event) => handleSubmit(event)}>
        <div>
          <Title
            style={{
              color: theme.colors.reduce[8],
            }}
          >
            <Typography variant={isSmallScreen ? 'h4' : 'h3'}>
              Bestill en gratis Reduce
              <br />
              Energianalyse av boligen din
            </Typography>
          </Title>
          <Space h={8} />
          <Typography variant="h6" fontWeight={400} color={theme.colors.reduce[8]}>
            Energianalysen gir deg informasjon om det reelle
            <br />
            strømforbruket ditt, og hva du kan gjøre for å forbedre det.
          </Typography>

          <Space h={24} />

          <Link
            style={{ color: theme.colors.reduce[6], cursor: 'pointer' }}
            onClick={() => {
              focusReference();
              toggleOpen();
            }}
          >
            <Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: 16 }}>{REGISTER_HVORDAN}</Typography>
          </Link>

          <Space h={24} />

          <div>
            <Group style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                InputProps={{
                  style: { borderRadius: '4.3px', width: isSmallScreen ? 'calc(80vw)' : 'calc(15vw)' },
                }}
                InputLabelProps={{
                  style: { color: theme.colors.blue[0] },
                }}
                label="Ditt fornavn"
                variant="outlined"
                {...form.getInputProps('firstName')}
                name={'given-name'}
                helperText={form.errors.firstName && form.errors.firstName}
                error={Boolean(form.errors.firstName)}
              />
              <TextField
                InputProps={{
                  style: { borderRadius: '4.3px', width: isSmallScreen ? 'calc(80vw)' : 'calc(15vw)' },
                }}
                InputLabelProps={{
                  style: { color: theme.colors.blue[0] },
                }}
                label="Ditt etternavn"
                variant="outlined"
                {...form.getInputProps('lastName')}
                name={'family-name'}
                helperText={form.errors.lastName && form.errors.lastName}
                error={Boolean(form.errors.lastName)}
              />
            </Group>
            <br />
            <Group style={{ alignItems: 'flex-start' }}>
              <TextField
                InputProps={{
                  style: { borderRadius: '4.3px', width: isSmallScreen ? 'calc(80vw)' : 'calc(15vw)' },
                }}
                InputLabelProps={{
                  style: { color: theme.colors.blue[0] },
                }}
                label="Din e-post"
                variant="outlined"
                {...form.getInputProps('email')}
                name={'email'}
                helperText={form.errors.email && form.errors.email}
                error={Boolean(form.errors.email)}
              />
              <TextField
                InputProps={{
                  style: { borderRadius: '4.3px', width: isSmallScreen ? 'calc(80vw)' : 'calc(15vw)' },
                }}
                InputLabelProps={{
                  style: { color: theme.colors.blue[0] },
                }}
                label="Ditt mobilnummer"
                variant="outlined"
                {...form.getInputProps('mobile')}
                name={'mobile'}
                helperText={form.errors.mobile && form.errors.mobile}
                error={Boolean(form.errors.mobile)}
              />
            </Group>
            <br />
          </div>

          <Space h={12} />

          <div style={{ display: 'flex', flexDirection: 'column', verticalAlign: 'center' }}>
            <Checkbox
              color="green"
              iconColor="dark.8"
              size="md"
              radius={5.3}
              label={
                <div style={{ fontSize: 20, fontWeight: 400 }}>
                  <span>Jeg aksepterer </span>
                  <a
                    href={REDUCE_AVTALEVILKAR}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: theme.colors.reduce[6], fontWeight: 700 }}
                  >
                    Reduce sine vilkår
                  </a>
                </div>
              }
              {...form.getInputProps('terms', { type: 'checkbox' })}
            />

            <Space h={16} />

            <Checkbox
              color="green"
              iconColor="dark.8"
              size="md"
              radius={5.3}
              label={
                <div style={{ fontSize: 20, fontWeight: 400 }}>
                  <span>Jeg aksepterer hvordan Reduce behandler </span>
                  <a
                    href={REDUCE_PERSONVERN}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: theme.colors.reduce[6], fontWeight: 700 }}
                  >
                    personopplysningene
                  </a>
                  <span> mine</span>
                </div>
              }
              {...form.getInputProps('person', { type: 'checkbox' })}
            />
            {registerError && <div className={'errorContainer'}>{registerError}</div>}

            <Space h={32} />

            <Button
              type="submit"
              style={{
                maxWidth: '175px',
                borderRadius: '4px',
                background: form.isValid() ? '#3A503B' : 'grey',
                color: '#fff',
              }}
              disabled={!form.isValid() /*|| !selectedBruksenhet*/}
            >
              <Box p={2} maxHeight={32} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ textTransform: 'none', fontWeight: 700, marginLeft: '16px', marginRight: '16px' }}>
                  Gå videre
                </Typography>
              </Box>
            </Button>
          </div>
        </div>
      </form>
      {opened && userUuid && (
        <RoutingModal
          opened={opened}
          onClose={() => {
            close();
            handleRoutingModalClose();
          }}
          userUuid={userUuid}
          numberUsed={isMobileConflictError}
          wideVersion={isSmallScreen}
        />
      )}
      <Space h={32} />
    </div>
  );
};
