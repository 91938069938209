import { AppShell, Group } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { REDUCE_ENERGY_URL } from './utils/constants';
import React from 'react';
import { SignOutButton } from './components/Login/SignOutButton';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from 'components/Logo';

export const Layout: React.FC = () => {
  const isAuthenticated = useAuth0().isAuthenticated;

  return (
    <AppShell data-testid="layout-header" header={{ height: 75 }} padding="md">
      <AppShell.Header style={{ display: 'flex', alignItems: 'center' }}>
        <Group style={{ marginLeft: 15, display: 'flex', flexDirection: 'row' }}>
          <a href={REDUCE_ENERGY_URL} style={{ textDecoration: 'none', width: 86 }}>
            <Logo />
          </a>
          {isAuthenticated && <SignOutButton />}
        </Group>
      </AppShell.Header>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
