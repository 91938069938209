import styles from './styles.module.css';

import React from 'react';
import Card from '../../Card';

interface YearCardProps {
  year: number;
  isFinalYear: boolean;
  previousYearTotalValue: number;
  sumOfFirstYear: number;
  percentageChange: number;
  multipleYears: boolean;
  currentYear: number;
}

export const YearCard = ({
  year,
  isFinalYear,
  previousYearTotalValue,
  sumOfFirstYear,
  percentageChange,
  multipleYears,
  currentYear,
}: YearCardProps) => {
  // const theme = useMantineTheme();
  const improvedUsage = percentageChange <= 0;
  const validPercentage = !isNaN(percentageChange);
  const pillStatusColor = !multipleYears
    ? '#fff'
    : improvedUsage && isFinalYear
      ? '#6ED77C'
      : !improvedUsage && isFinalYear
        ? '#E28D8D'
        : '#fff';

  return (
    <Card>
      <div className={styles.top}>
        <span className={styles.year}>{year}</span>
        {isFinalYear && multipleYears && validPercentage && (
          <div className={styles.pill} style={{ backgroundColor: pillStatusColor }}>
            {percentageChange !== 0 && (
              <span>
                {percentageChange > 0 ? '+' : ''}
                {percentageChange.toFixed(0)}% {currentYear === year ? <>hittil i år</> : <>endring</>}
              </span>
            )}
          </div>
        )}
      </div>
      <h2 className={styles.usage}>
        {isFinalYear ? previousYearTotalValue.toFixed(0) : sumOfFirstYear.toFixed(0)} kWh
      </h2>
      <span className={styles.bottom}>Registrert forbruk</span>
    </Card>
  );
};
