import styles from './styles.module.css';
import React from 'react';

type Props = {
  children: React.ReactNode;
}
const Card = ({ children }: Props) => {
  return (
    <section className={styles.card}>
      {children}
    </section>
  )
};

export default Card;