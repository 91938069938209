import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import { ExpandCircleDown } from '@mui/icons-material';
import { Button, Space, useMantineTheme } from '@mantine/core';
import { AnbefalModal } from './AnbefalModal';
import { useDisclosure } from '@mantine/hooks';

type DropdownInfoProps = {
  isOpen: boolean;
  toggleOpen: () => void;
};

export const DropdownInfo: React.FC<DropdownInfoProps> = ({ isOpen, toggleOpen }) => {
  const theme = useMantineTheme();

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div style={{ maxWidth: '90%' }}>
      <Divider />
      <Accordion style={{ boxShadow: 'none' }} expanded={isOpen} onChange={toggleOpen}>
        <AccordionSummary expandIcon={<ExpandCircleDown fontSize="large" />}>
          <Typography style={{ fontWeight: 700, color: theme.colors.reduce[8] }}>Hvordan fungerer det?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <DropdownExplanation />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Space h={1} />
      <Divider />
      <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandCircleDown fontSize="large" />}>
          <Typography style={{ fontWeight: 700, color: theme.colors.reduce[8] }}>Ofte stilte spørsmål</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <DropdownQA />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Space h={1} />
      <Divider />
      <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandCircleDown fontSize="large" />}>
          <Typography style={{ fontWeight: 700, color: theme.colors.reduce[8] }}>Anbefal energianalysen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Hvis du ønsker å dele muligheten med andre, klikk her for å sende en anbefaling:
            <Button variant="filled" size="small" onClick={open}>
              Send e-post
            </Button>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <AnbefalModal opened={opened} onClose={close} wideVersion={false} />
    </div>
  );
};

const DropdownExplanation: React.FC = () => (
  <>
    Dette er første versjon av Reduce energianalyse. Tjenesten er fortsatt under utvikling, og er gratis for de første
    som prøver tjenesten. <br />
    <br />
    En energianalyse av boligen din gir deg informasjon om det reelle strømforbruket på din bolig, sammen med
    temperaturen og andre interessante data der du bor. <br />
    Du kan se hva som brukes på oppvarming, hvor mye som alltid står på, og det variable forbruket ditt. <br />
    <br />
    Formålet med analysen er å gi deg en pekepin på hvilke tiltak som kan være gunstig og kostnadsbesparende for din
    husholdning. <br />
    Rapporten kan også brukes som grunnlag ved salg av eiendom som støtte til opplysningsplikten ref. avhendingsloven.{' '}
    <br />
    <br />
    Dersom du har gjort energibesparende tiltak, som mer energieffektive varmekilder eller etterisolering, kan du se
    hvordan dette har påvirket det reelle forbruket ditt. <br />
    <br />
    For å gjøre analysen trenger vi tilgang til det historiske energiforbruket til boligen din. Dette henter vi fra
    Elhub, og det må du gi samtykke til med BankID. <br />
    I tillegg til energiforbruk, bruker vi værdata fra Meteorologisk Institutt og prisinformasjon fra Norpol og
    boligdata fra Unbolt. <br />
    <br />I Betaversjonen er rapporten forenklet, og ikke alle datakilder er koblet på.
  </>
);

const DropdownQA: React.FC = () => (
  <>
    <strong>Hvordan bruker jeg tjenesten?</strong>
    <br />
    Her på{' '}
    <a href="https://app.reduceenergy.no/register" target="_blank" rel="noopener noreferrer">
      https://app.reduceenergy.no/register
    </a>{' '}
    skriver du inn din informasjon, for at vi kan finne din bolig og tilby en energirapport. Formatet på epost,
    mobilnummer og navn er veldig viktig.
    <br />
    <br />
    <strong>Hvordan utføres identitetsvalidering?</strong>
    <br />
    Identitetsvalidering gjøres ved å fylle ut registreringsskjemaet med nødvendig informasjon, inkludert navn, e-post,
    og mobilnummer. Bank-ID håndterer prosessen på den sikreste måten, og vi får beskjed om det gikk greit når det er
    gjennomført.
    <br />
    <br />
    <strong>Hvordan samler tjenesten inn strømforbruksdata?</strong>
    <br />
    Reduce Energy samler strømforbruksdata ved å koble seg til relevante datakilder og analysere forbruket over tid.
    Dette kan inkludere måleinstrumenter, smarte enheter og annen tilgjengelig informasjon.
    <br />
    <br />
    <strong>Hvor kommer temperaturmålepunktene fra?</strong>
    <br />
    Temperaturmålepunktene stammer fra Frost API, en åpen plattform som gir tilgang til meteorologisk informasjon. Dette
    brukes til å forbedre nøyaktigheten i din energianalyse ved å inkludere værforholdene.
    <br />
    <br />
    <strong>Hvorfor er tjenesten gratis?</strong>
    <br />
    Tjenesten tilbys gratis fordi den for øyeblikket er i beta-testingfasen. Vi ønsker å gi brukerne muligheten til å
    utforske og dra nytte av tjenesten uten kostnad mens vi samler verdifull tilbakemelding og gjør forbedringer.
    <br />
    <br />
    <strong>Hvorfor tar det tid å generere energirapporten?</strong>
    <br />
    Genereringen av energirapporten tar litt tid fordi tjenesten samler inn og analyserer flere datapunkter for å gi en
    grundig og nøyaktig analyse av strømforbruket ditt. Dette sikrer at du mottar mest mulig verdifull informasjon om
    din boligs energieffektivitet.
  </>
);
