import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import EnergyReport from '../components/EnergyReport';
import Error from '../components/Error';
import { Loader, useMantineTheme } from '@mantine/core';
import {
  getDailyComparedUsage,
  getDailyUsage,
  getMaxThreeYearUsage,
  getMeterpointByUuid,
  getMonthlyTemperatures,
  getMonthlyUsage,
} from '../api/meterpoints';

export default function EnergyReportPage() {
  const theme = useMantineTheme();
  const { id } = useParams();

  useEffect(() => {
    document.title = 'Reduce energirapport';
  });

  const {
    isLoading: isMeterpointLoading,
    isError: isMeterpointError,
    data: meterpoint,
  } = useQuery({
    queryKey: ['meterpoint', id || '1'],
    queryFn: ({ queryKey }) => {
      return getMeterpointByUuid(queryKey[1]);
    },
  });

  const {
    isLoading: isMonthlyUsageLoading,
    isError: isMonthlyUsageError,
    data: monthlyUsage,
  } = useQuery({
    queryKey: ['monthlyUsage', id || '1'],
    queryFn: ({ queryKey }) => getMonthlyUsage(queryKey[1]),
  });

  const {
    isLoading: isYearlyUsageLoading,
    isError: isYearlyUsageError,
    data: yearlyUsage,
  } = useQuery({
    queryKey: ['yearlyUsage', id || '1'],
    queryFn: ({ queryKey }) => getMaxThreeYearUsage(queryKey[1]),
  });

  const {
    isLoading: isDailyUsageLoading,
    isError: isDailyUsageError,
    data: dailyUsage,
  } = useQuery({
    queryKey: ['dailyUsage', id || '1'],
    queryFn: ({ queryKey }) => getDailyUsage(queryKey[1]),
  });

  const {
    isLoading: isDailyComparedUsageLoading,
    isError: isDailyComparedUsageError,
    data: dailyComparedUsage,
  } = useQuery({
    queryKey: ['dailyComparedUsage', id || '1'],
    queryFn: ({ queryKey }) => getDailyComparedUsage(queryKey[1]),
  });

  const {
    isLoading: isMonthlyTemperatureLoading,
    isError: isMonthlyTemperatureError,
    data: monthlyTemperatures,
  } = useQuery({
    queryKey: ['monthlyTemperatures', id || '1'],
    queryFn: ({ queryKey }) => getMonthlyTemperatures(queryKey[1]),
  });

  if (
    isMeterpointLoading ||
    isYearlyUsageLoading ||
    isMonthlyUsageLoading ||
    isDailyUsageLoading ||
    isDailyComparedUsageLoading ||
    isMonthlyTemperatureLoading
  ) {
    return <Loader color={theme.colors.teal[4]} />;
  }
  if (
    isMeterpointError ||
    isYearlyUsageError ||
    isMonthlyUsageError ||
    isDailyUsageError ||
    isDailyComparedUsageError ||
    isMonthlyTemperatureError
  ) {
    return <Error />;
  }
  return (
    <EnergyReport
      meterpoint={meterpoint}
      yearlyConsumption={yearlyUsage}
      monthlyConsumption={monthlyUsage}
      dailyConsumption={dailyUsage}
      dailyComparedConsumption={dailyComparedUsage}
      monthlyTemperature={monthlyTemperatures}
    />
  );
}
